/**
 * Parses a single enum definition and returns the enum name and its values
 * @param {string} enumDefinition Enum definition string
 * @returns {Object|null} Object containing enum name and values, or null if invalid
 */
const parseEnumDefinitions = (enumDefinition) => {
  // Match the enum name and values using regex
  const match = enumDefinition.match(/enum\s+(\w+)\s*{([^}]*)}/);
  if (match) {
    const enumName = match[1];
    // Split values and clean them
    const enumValues = match[2]
      .split(",")
      .map((value) => value.trim())
      .filter((value) => value.length > 0);

    return {
      name: enumName,
      values: enumValues,
    };
  }
  return null;
};

export default parseEnumDefinitions;
