import React, { useEffect, useState, useRef } from "react";
import StyledConditionSection from "./StyledConditionSection";
import useFetch from "../../../utils/hooks/useFetch";
import {
  CONDITION_INPUTS,
  CONDITION_ROW2,
  CONDITION_SECTION,
  CUSTOM_INDICATORS,
} from "../../../constants/routes/api-routes";
import Input from "../../Input";
import { useFormContext } from "react-hook-form";
import getNestedValue from "../../../utils/functions/getNestedValue";
import exceptions from "../../../constants/exeptions";
import ConditionAccordion from "../ConditionAccordion";
import { useFlowStore } from "../../../store/flowStore";
import parseEnumDefinitions from "../../../utils/functions/parser/enumParser";

const processInputsWithEnums = (inputParameters, enumDefinitions) => {
  // Create a map of enum types to their values
  const enumMap = new Map();
  enumDefinitions.forEach((enumDef) => {
    const parsed = parseEnumDefinitions(enumDef);
    if (parsed) {
      enumMap.set(parsed.name, parsed.values);
    }
  });

  // Process inputs and check for enum types
  return inputParameters.map((param, index) => {
    // Check if the parameter type matches an enum name
    if (enumMap.has(param.type)) {
      const enumValues = enumMap.get(param.type);
      // Check existing value first, then default value, then first enum value
      const value =
        param.defaultValue && enumValues.includes(param.defaultValue)
          ? param.defaultValue
          : enumValues[0];

      return {
        id: param.name,
        key: param.name,
        type: "select",
        options: enumValues.map((value) => ({
          id: value,
          name: value,
        })),
        value: value.toString(),
        id_sent: index,
      };
    }

    // Handle boolean type
    if (param.type.toLowerCase() === "bool") {
      return {
        id: param.name,
        key: param.name,
        type: "select",
        options: [
          { id: "true", name: "True" },
          { id: "false", name: "False" },
        ],
        value: param.defaultValue.toString(),
        id_sent: index,
      };
    }

    // Default to text input
    return {
      id: param.name,
      key: param.name,
      type: "text",
      value: param.defaultValue.toString(),
      id_sent: index,
    };
  });
};

const ConditionSection = ({
  id,
  nodeId,
  defaultRow1 = null,
  defaultRow2 = null,
  defaultParams,
}) => {
  const flowStore = useFlowStore();
  const { setValue, register, getValues } = useFormContext(); // retrieve all hook methods
  const [, setForceRefreshState] = useState(true);
  const [subInputs, setSubInputs] = useState([]);
  //states
  const [firstRowList, setFirstRowList] = useState([]);
  const [row1, setRow1] = useState(null);

  const [row2, setRow2] = useState(
    Object.keys(
      flowStore.nodes?.find((property) => property.id === nodeId)?.params
    ).length > 0 &&
      flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
      ? getNestedValue(
          flowStore.nodes?.find((property) => property.id === nodeId),
          `params.${id}.row2`
        ) ?? defaultRow2
      : defaultRow2
  );
  const [secondRowList, setSecondRowList] = useState([]);
  const [additionalProps, setAdditionalProps] = useState({
    inputs: [],
    additionalSelects: [],
  });

  //API calls

  useEffect(() => {
    if (defaultRow1) {
      const _row1Value =
        Object.keys(
          flowStore.nodes?.find((property) => property.id === nodeId)?.params
        ).length > 0 &&
        flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
          ? getNestedValue(
              flowStore.nodes?.find((property) => property.id === nodeId),
              `params.${id}.row1`
            ) ?? defaultRow1
          : defaultRow1;

      setValue(`params.${id}.row1`, _row1Value);

      setRow1(_row1Value);
    }
    // eslint-disable-next-line
  }, [defaultRow1]);

  useEffect(() => {
    const _row2Value =
      Object.keys(
        flowStore.nodes?.find((property) => property.id === nodeId)?.params
      ).length > 0 &&
      flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
        ? getNestedValue(
            flowStore.nodes?.find((property) => property.id === nodeId),
            `params.${id}.row2`
          ) ?? defaultRow2
        : defaultRow2;

    setRow2(_row2Value);
    setValue(`params.${id}.row2`, _row2Value);
    // eslint-disable-next-line
  }, [defaultRow2]);

  //getting the condition list
  const { data: firstRowData, isSuccess: firstRowIsSuccess } = useFetch(
    "GET",
    CONDITION_SECTION,
    CONDITION_SECTION
  );
  //getting the condition Details
  const { data, isSuccess, isLoading } = useFetch(
    "GET",
    [id, CONDITION_ROW2 + row1 + "/"],
    CONDITION_ROW2 + row1 + "/",
    null,
    null,
    {
      enabled:
        !!firstRowList.length > 0 &&
        row1?.length > 0 &&
        row1 !== process.env.REACT_APP_CUSTOM_INDICATOR_SLUG,
    }
  );

  const { data: fetchedInputs, isLoading: inputsLoading } = useFetch(
    "GET",
    [id, CONDITION_INPUTS(row1, row2), row2, row1],
    CONDITION_INPUTS(row1, row2),
    null,
    null,
    {
      enabled:
        !!row1 &&
        !!row2 &&
        row1?.length > 0 &&
        row2?.length > 0 &&
        row1 !== process.env.REACT_APP_CUSTOM_INDICATOR_SLUG &&
        !!secondRowList.find((item) => item?.id === row2),
    }
  );

  // Add new fetch for custom indicators
  const { data: customIndicators } = useFetch(
    "GET",
    [CUSTOM_INDICATORS, row1],
    CUSTOM_INDICATORS,
    null,
    null,
    {
      enabled: row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG,
    }
  );

  useEffect(() => {
    if (
      fetchedInputs?.data &&
      row1 !== process.env.REACT_APP_CUSTOM_INDICATOR_SLUG
    ) {
      console.log("2. Setting additionalProps in fetchedInputs effect");
      const _data = fetchedInputs.data;
      const _inputs = [];
      const _additionalSelects = [];

      _data?.conditions_input_field?.forEach((_input) => {
        _inputs.push({
          id: _input?.name_key,
          value: defaultParams?.params?.[_input?.name_key]
            ? defaultParams?.params?.[_input?.name_key]
            : _input?.name_value,
          type: "text",
          id_sent: _input?.id_sent,
          key: _input?.name_represent,
        });
      });

      _data?.conditions_dropdown_menu?.forEach((input) => {
        if (input?.condition_child_extra_feature_name.length > 0) {
          if (
            input?.name_key?.includes(exceptions.adjust) ||
            input?.name_key?.includes(exceptions.moreSettings)
          ) {
            const _additionalSelectInputs = [];
            input?.condition_child_extra_feature_name?.forEach(
              (additionalInput) => {
                if (additionalInput?.type === "text") {
                  _additionalSelectInputs.push({
                    id: additionalInput?.name_key,
                    key: additionalInput?.name_represent,
                    value: defaultParams?.params?.[additionalInput?.name_key]
                      ? defaultParams?.params?.[additionalInput?.name_key]
                      : additionalInput?.name_value,
                    id_sent: additionalInput?.id_sent,
                    type: "text",
                  });
                } else {
                  const _options = [];
                  additionalInput?.condition_grand_child_feature_name?.forEach(
                    (additionalInputOption) => {
                      _options.push({
                        id: additionalInputOption?.name_value,
                        name: additionalInputOption?.name_represent,
                        id_sent: additionalInputOption?.id_sent,
                      });
                    }
                  );
                  _additionalSelectInputs.push({
                    id: additionalInput?.name_key,
                    key: additionalInput?.name_represent,
                    value: defaultParams?.params?.[additionalInput?.name_key]
                      ? defaultParams?.params?.[additionalInput?.name_key]
                      : additionalInput?.name_value,
                    id_sent: additionalInput?.id_sent,
                    type: "select",
                    options: _options,
                  });
                }
              }
            );
            _additionalSelects.push({
              id: input?.name_key,
              key: input?.name_value,
              id_sent: input?.id_sent,
              inputs: _additionalSelectInputs?.sort((a, b) =>
                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
              ),
            });
          } else {
            const _options = [];
            let inputId =
              input?.condition_child_extra_feature_name[0]?.name_key;
            input?.condition_child_extra_feature_name?.forEach((option) => {
              const _subInputs = [];
              option?.condition_grand_child_feature_name?.forEach(
                (_subInput) => {
                  _subInputs.push({
                    id: _subInput?.name_key,
                    key: _subInput?.name_represent,
                    id_sent: _subInput?.id_sent,
                    type: "text",
                    value: defaultParams?.params?.[_subInput?.name_key]
                      ? defaultParams?.params?.[_subInput?.name_key]
                      : _subInput?.name_value,
                  });
                }
              );

              option?.condition_grand_child_extra_feature_name?.forEach(
                (_subInput) => {
                  const _subInputOptions = [];
                  if (
                    _subInput?.grand_child_ofchild_extra_feature_name?.length >
                    0
                  ) {
                    let _subInputId =
                      _subInput?.grand_child_ofchild_extra_feature_name[0]
                        ?.name_key;
                    _subInput?.grand_child_ofchild_extra_feature_name?.forEach(
                      (subInputOption) => {
                        _subInputOptions.push({
                          id: subInputOption?.name_value,
                          name: subInputOption?.name_represent,
                          id_sent: subInputOption?.id_sent,
                        });
                      }
                    );
                    _subInputs.push({
                      id: _subInputId,
                      key: _subInput?.name_represent,
                      type: "select",
                      id_sent: _subInput?.id_sent,
                      value: defaultParams?.params?.[_subInputId]
                        ? defaultParams?.params?.[_subInputId]
                        : _subInput?.name_value === "NULL"
                        ? _subInputOptions[0]?.id
                        : _subInput?.name_value,
                      options: _subInputOptions?.sort((a, b) =>
                        Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                      ),
                    });
                  }
                }
              );

              _options.push({
                id: option?.name_value,
                name: option?.name_represent,
                id_sent: option?.id_sent,
                subInputs: _subInputs?.sort((a, b) =>
                  Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                ),
              });
            });

            _inputs.push({
              id: inputId,
              key: input?.name_key,
              type: "select",
              id_sent: input?.id_sent,
              value: defaultParams?.params?.[inputId]
                ? defaultParams?.params?.[inputId]
                : input?.name_value === "NULL" && !input?.name_value
                ? _options[0].id
                : input?.name_value,
              options: _options?.sort((a, b) =>
                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
              ),
            });
          }
        }
      });
      console.log("in fetchedInputs effect", _inputs);
      setAdditionalProps({
        additionalSelects: _additionalSelects?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        ),
        inputs: _inputs?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        ),
      });
    }
  }, [fetchedInputs]);

  //set condition list state
  useEffect(() => {
    if (firstRowIsSuccess && firstRowData) {
      const _data = firstRowData.data[0];
      const _conditions = [];
      _data?.children_name?.forEach((condition) => {
        _conditions.push({
          id: condition?.slug,
          name: condition?.child_name,
          id_sent: condition?.id_sent,
        });
      });
      if ((row1 === undefined || row1 === null) && !defaultRow1) {
        setRow1(_conditions[0]?.id);
        setValue(`params.${id}.row1`, _conditions[0]?.id);
      } else if (row1) {
        setValue(`params.${id}.row1`, row1);
      } else {
        setRow1(defaultRow1);
        setValue(`params.${id}.row1`, defaultRow1);
      }
      setRow2("");
      setFirstRowList(
        _conditions?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      );
    }
    //eslint-disable-next-line
  }, [firstRowIsSuccess, firstRowData]);

  // useEffect(() => {
  //   if (row1) {
  //     setValue(`params.${id}.row1`, row1);
  //   }
  //   // eslint-disable-next-line
  // }, [row1]);

  useEffect(() => {
    register(`params.${id}.params`, { value: {} });
    const _properties = flowStore.nodes;
    const propertyIndex = _properties?.findIndex(
      (property) => property.id === nodeId
    );

    const _row2 =
      propertyIndex >= 0
        ? getNestedValue(_properties[propertyIndex], `params.${id}.row2`)
        : null;
    const _row1 =
      propertyIndex >= 0
        ? getNestedValue(_properties[propertyIndex], `params.${id}.row1`)
        : null;
    if (propertyIndex >= 0 && _row2) {
      setRow2(_row2);
      setValue(`params.${id}.row2`, _row2);
    }
    if (propertyIndex >= 0 && _row1) {
      setRow1(_row1);
      setValue(`params.${id}.row1`, _row1);
    }
    // eslint-disable-next-line
  }, []);

  //parse data to get the condition Details
  useEffect(() => {
    if (row1) {
      setValue(`params.${id}.row1`, row1);
    }

    // Handle custom indicators differently
    if (
      row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG &&
      customIndicators?.data
    ) {
      const _secondRowList = customIndicators.data.map((indicator) => ({
        id: indicator.child_name,
        name: indicator.child_name,
        id_sent: indicator.id,
        jsonFile: indicator.json_file,
      }));

      setSecondRowList(_secondRowList);

      // If we have a defaultRow2 or selecting first item
      if (_secondRowList.length > 0) {
        const selectedName = defaultRow2 || _secondRowList[0].name;
        setRow2(selectedName);
        setValue(`params.${id}.row2`, selectedName);

        const selectedIndicator = customIndicators.data.find(
          (ind) => ind.child_name === selectedName
        );

        if (selectedIndicator?.json_file) {
          try {
            const indicatorData = JSON.parse(selectedIndicator.json_file);

            // Set up the params structure
            const params = {
              input: indicatorData.inputParameters.map((param) => ({
                type: param.type,
                name: param.name,
                value: param.defaultValue,
              })),
              enums: indicatorData.enumerations || [],
              buffer: indicatorData.outputBuffers?.length || 0,
            };

            // Set the form values
            setValue(`params.${id}.params`, params);

            // Process inputs with enum handling
            const inputs = processInputsWithEnums(
              indicatorData.inputParameters,
              indicatorData.enumerations || []
            );
            console.log("in custom indicator effect", inputs);
            setAdditionalProps({
              inputs,
              additionalSelects: [],
            });
          } catch (error) {
            console.error("Error parsing custom indicator data:", error);
            setAdditionalProps({ inputs: [], additionalSelects: [] });
          }
        }
      }
    } else if (isSuccess && data) {
      // Original non-custom indicator logic
      const _secondRowList = [];
      data?.data?.condition_menu_child_feature?.forEach((item) => {
        _secondRowList.push({
          id: item?.slug,
          name: item?.name_key,
          id_sent: item?.id_sent,
        });
      });
      setSecondRowList(
        _secondRowList?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      );
    }
  }, [isSuccess, data, row1, customIndicators, defaultRow2]);

  //after changing condition this happens to set the first item as default
  useEffect(() => {
    if (secondRowList.length > 0) {
      const _properties = flowStore.nodes;
      const propertyIndex = _properties?.findIndex(
        (property) => property.id === nodeId
      );
      if (
        typeof propertyIndex === "undefined" ||
        propertyIndex < 0 ||
        (propertyIndex >= 0 &&
          getNestedValue(_properties[propertyIndex], `params.${id}.row1`) !==
            row1)
      ) {
        const _row2 =
          defaultRow2?.length > 0 &&
          secondRowList.find((item) => item?.id === defaultRow2)
            ? defaultRow2
            : secondRowList[0]?.id;
        setRow2(_row2);
        setValue(`params.${id}.row2`, _row2);
      } else {
        if (propertyIndex >= 0) {
          const _row2 =
            propertyIndex >= 0
              ? getNestedValue(_properties[propertyIndex], `params.${id}.row2`)
              : null;
          if (_row2) {
            setRow2(_row2);
            setValue(`params.${id}.row2`, _row2);
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [secondRowList]);

  //handlers

  // Modify the inputChangeHandler
  const inputChangeHandler = (key, value) => {
    const currentParams = getValues(`params.${id}.params`);

    if (!currentParams) {
      // Initialize params if they don't exist
      setValue(`params.${id}.params`, {
        input: [],
        enums: [],
        buffer: 0
      });
    }

    if (key === "buffer") {
      setValue(`params.${id}.params.buffer`, value);
    } else {
      // Handle both custom indicator inputs and normal inputs
      if (row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG) {
        // Update the input array for custom indicators
        const inputIndex = currentParams?.input?.findIndex(
          (input) => input.name === key
        );
        if (inputIndex !== -1) {
          const newInput = [...(currentParams.input || [])];
          newInput[inputIndex] = {
            ...newInput[inputIndex],
            value: value
          };
          setValue(`params.${id}.params.input`, newInput);
        }
      } else {
        // Handle normal select inputs
        try {
          setValue(`params.${id}.params.${key}`, value);
        } catch (error) {
          console.error("Error setting value:", error);
        }
      }
    }

    // Force refresh to update UI
    setForceRefreshState((prev) => !prev);
  };

  //handle change of condition details
  const indicatorChangeHandler = (selectedItem) => {
    const newValue = selectedItem.target.value;

    // Handle custom indicators
    if (row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG && customIndicators?.data) {
      const selectedIndicator = customIndicators.data.find(
        (item) => item.child_name === newValue
      );

      if (selectedIndicator?.json_file) {
        try {
          const indicatorData = JSON.parse(selectedIndicator.json_file);
          const processedInputs = processInputsWithEnums(
            indicatorData.inputParameters,
            indicatorData.enumerations || []
          );
          
          setAdditionalProps({
            inputs: processedInputs,
            additionalSelects: []
          });
        } catch (error) {
          console.error("Error parsing custom indicator data:", error);
        }
      }
    } else {
      // Handle normal conditions
      setRow2(newValue);
      setValue(`params.${id}.row2`, newValue);
      
      // Clear previous inputs when changing row2
      setAdditionalProps({
        inputs: [],
        additionalSelects: []
      });
    }
  };

  //handle change of condition
  const conditionChangeHandler = (_selectedCondition) => {
    setRow2("");
    setRow1(_selectedCondition.target.value);
  };

  // Add new helper function within component
  const createInputField = (param, enumMap, id_sent, existingParams) => {
    // Handle enum types
    if (enumMap.has(param.type)) {
      const enumValues = enumMap.get(param.type);
      // Check existing value first, then default value, then first enum value
      const value =
        existingParams?.[param.name] ||
        (enumValues.includes(param.defaultValue)
          ? param.defaultValue
          : enumValues[0]);

      return {
        id: param.name,
        key: param.name,
        type: "select",
        options: enumValues.map((value) => ({
          id: value,
          name: value,
        })),
        value: value.toString(),
        id_sent,
      };
    }

    // Handle boolean type
    if (param.type.toLowerCase() === "bool") {
      const value =
        existingParams?.[param.name] !== undefined
          ? existingParams[param.name].toString()
          : param.defaultValue?.toString()?.toLowerCase() === "true"
          ? "true"
          : "false";

      return {
        id: param.name,
        key: param.name,
        type: "select",
        options: [
          { id: "true", name: "True" },
          { id: "false", name: "False" },
        ],
        value,
        id_sent,
      };
    }

    // Default to text input
    return {
      id: param.name,
      key: param.name,
      type: "text",
      value: (
        existingParams?.[param.name] ??
        param.defaultValue ??
        ""
      ).toString(),
      id_sent,
    };
  };

  // Remove or modify the useEffect that was processing fetchedInputs
  // Instead, process the JSON file data when loading a custom indicator
  useEffect(() => {
    if (
      row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG &&
      row2 &&
      customIndicators?.data
    ) {
      const selectedIndicator = customIndicators.data.find(
        (item) => item.child_name === row2
      );

      if (selectedIndicator?.json_file) {
        try {
          const indicatorData = JSON.parse(selectedIndicator.json_file);

          // Process input parameters from the JSON file
          const inputs = indicatorData.inputParameters.map((param, index) => ({
            id: param.name,
            key: param.name,
            type: "text", // You might want to map different types based on param.type
            value: param.defaultValue,
            id_sent: index,
          }));
          console.log("indicatorChangeHandler - inputs:", inputs);

          setAdditionalProps({
            inputs,
            additionalSelects: [],
          });
        } catch (error) {
          console.error("Error parsing custom indicator data:", error);
          setAdditionalProps({ inputs: [], additionalSelects: [] });
        }
      }
    }
  }, [row1, row2, customIndicators]);

  // Add a ref to track initial load
  const initialLoadRef = useRef(false);

  // Add a component ID to track which instance we're dealing with
  const componentId = useRef(`condition-section-${id}-${nodeId}`);

  // Modify the initial data load effect
  useEffect(() => {
    const _properties = flowStore.nodes;
    const propertyIndex = _properties?.findIndex(
      (property) => property.id === nodeId
    );

    if (propertyIndex >= 0) {
      const storedRow2 = getNestedValue(
        _properties[propertyIndex],
        `params.${id}.row2`
      );
      const storedRow1 = getNestedValue(
        _properties[propertyIndex],
        `params.${id}.row1`
      );

      if (
        storedRow1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG &&
        customIndicators?.data
      ) {
        // Only set row2 if it exists in store or we don't have a current value
        if (storedRow2 || !row2) {
          const targetRow2 = storedRow2 || customIndicators.data[0]?.child_name;
          setRow2(targetRow2);
          setValue(`params.${id}.row2`, targetRow2);
        }

        // Find the selected indicator and process its inputs
        const selectedIndicator = customIndicators.data.find(
          (ind) => ind.child_name === (storedRow2 || row2)
        );

        if (selectedIndicator?.json_file) {
          try {
            const indicatorData = JSON.parse(selectedIndicator.json_file);
            const processedInputs = processInputsWithEnums(
              indicatorData.inputParameters,
              indicatorData.enumerations || []
            );

            console.log(
              `${componentId.current} - Initial load:`,
              processedInputs
            );

            setAdditionalProps({
              inputs: processedInputs,
              additionalSelects: [],
            });
            initialLoadRef.current = true;
          } catch (error) {
            console.error("Error parsing custom indicator data:", error);
          }
        }
      }
    }
  }, [customIndicators, id]); // Add id to dependencies

  // Modify the row1/row2 effect
  useEffect(() => {
    if (
      row1 === process.env.REACT_APP_CUSTOM_INDICATOR_SLUG &&
      customIndicators?.data
    ) {
      const _secondRowList = customIndicators.data.map((indicator) => ({
        id: indicator.child_name,
        name: indicator.child_name,
        id_sent: indicator.id,
        jsonFile: indicator.json_file,
      }));

      setSecondRowList(_secondRowList);

      // If we have a defaultRow2 or selecting first item
      if (_secondRowList.length > 0) {
        // Preserve existing row2 value if it exists
        const currentRow2 = row2 || defaultRow2 || _secondRowList[0].name;

        if (!row2) {
          setRow2(currentRow2);
          setValue(`params.${id}.row2`, currentRow2);
        }

        const selectedIndicator = customIndicators.data.find(
          (ind) => ind.child_name === currentRow2
        );

        if (selectedIndicator?.json_file) {
          try {
            const indicatorData = JSON.parse(selectedIndicator.json_file);
            const processedInputs = processInputsWithEnums(
              indicatorData.inputParameters,
              indicatorData.enumerations || []
            );

            // Only update if this instance's inputs need updating
            if (
              !initialLoadRef.current ||
              !additionalProps.inputs.some((input) => input.type === "select")
            ) {
              setAdditionalProps({
                inputs: processedInputs,
                additionalSelects: [],
              });
              initialLoadRef.current = true;
            }
          } catch (error) {
            console.error("Error parsing custom indicator data:", error);
          }
        }
      }
    }
  }, [row1, row2, customIndicators, defaultRow2, id]); // Add id to dependencies

  return (
    <StyledConditionSection>
      <div className="conditionTitle">
        {id === "left" ? "Left Operand" : id === "right" && "Right Operand"}
      </div>
      <div className="mainContainer">
        {firstRowList.length > 0 && (
          <div className="mainDropdownHolder">
            <Input
              id={id}
              registerId={`params.${id}.row1`}
              input={{
                key: "",
                type: "select",
                id: "condition",
                options: firstRowList,
              }}
              type={"select"}
              value={row1}
              nodeId={nodeId}
              onChange={conditionChangeHandler}
              disableVariables
            />
          </div>
        )}
        {isLoading ? (
          <span>loading...</span>
        ) : (
          <>
            {secondRowList && secondRowList.length > 0 && (
              <div className="mainDropdownHolder">
                <Input
                  id={id}
                  input={{
                    key: "",
                    id: "conditionDetail",
                    options: secondRowList,
                  }}
                  registerId={`params.${id}.row2`}
                  nodeId={nodeId}
                  type={"select"}
                  onChange={indicatorChangeHandler}
                  disableVariables
                />
              </div>
            )}
            <div className="additionalHolder">
              {inputsLoading ? (
                <span>loading...</span>
              ) : (
                <>
                  {additionalProps?.inputs?.length > 0 &&
                    additionalProps.inputs
                      ?.sort((a, b) =>
                        Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                      )
                      .map((input, index) => (
                        <div key={`${input?.id}-${input?.id}-${index}`}>
                          <Input
                            key={input?.id + input?.key}
                            id={id}
                            input={input}
                            nodeId={nodeId}
                            value={input?.value}
                            registerId={`params.${id}.params.${input?.id}`}
                            inputChangeHandler={
                              input?.type === "select" && inputChangeHandler
                            }
                            setSubInputs={
                              input?.type === "select" && setSubInputs
                            }
                            type={input?.type}
                          />
                          {input?.type === "select" &&
                            subInputs.find(
                              (e) => e.id === `params.${id}.params.${input?.id}`
                            ) &&
                            subInputs
                              .find(
                                (e) =>
                                  e.id === `params.${id}.params.${input?.id}`
                              )
                              ?.subInputs?.sort((a, b) =>
                                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                              )
                              ?.map((subInput, index) => (
                                <Input
                                  key={subInput.id + index}
                                  value={subInput?.value}
                                  id={id}
                                  input={subInput}
                                  registerId={`params.${id}.params.${subInput?.id}`}
                                  type={subInput?.type}
                                  nodeId={nodeId}
                                />
                              ))}
                        </div>
                      ))}
                </>
              )}
            </div>
            <div className="additionalSelectBoxContainer">
              {inputsLoading ? (
                <span>loading...</span>
              ) : (
                <>
                  {additionalProps?.additionalSelects?.length > 0 &&
                    additionalProps?.additionalSelects?.map(
                      (additionalSelect) => (
                        <ConditionAccordion
                          additionalSelect={additionalSelect}
                          id={id}
                          inputChangeHandler={inputChangeHandler}
                          nodeId={nodeId}
                          key={additionalSelect?.id}
                        />
                      )
                    )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </StyledConditionSection>
  );
};

export default ConditionSection;
