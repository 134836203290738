import React, { memo, useEffect, useState } from "react";
import StyledInput from "./StyledInput";
import SetVariableModal from "../SetVariableModal";
import { useFormContext } from "react-hook-form";
import ConditionSection from "../ProppertiesModal/ConditionSection";
import getNestedValue from "../../utils/functions/getNestedValue";
import { useFlowStore } from "../../store/flowStore";
import { useVariableStore } from "../../store/variableStore";

const Input = memo(
  ({
    id,
    input,
    nodeId,
    inputChangeHandler,
    type,
    value,
    onChange,
    registerId,
    defaultParams,
    className,
    setSubInputs,
    disableVariables,
  }) => {
    const flowStore = useFlowStore();
    const variableStore = useVariableStore();
    const _inputRegisterId =
      registerId && registerId?.length > 0 ? registerId : `params.${input?.id}`;
    const { register, setValue, getValues, unregister } = useFormContext(); // retrieve all hook methods

    const [variableModal, setVariableModal] = useState(false);

    const [variable, setVariable] = useState({
      id: "",
      name: "",
      type: "",
    });

    const placingVariables = (value) => {
      const _variables = variableStore.Variables;
      const _constants = variableStore.Constants;
      const _oldValue = variableStore.oldValues;
      let isValid = true;
      _oldValue.forEach((_old) => {
        if (_old?.variable.name === value) {
          const _foundedNode = _old?.nodes.find((_node) => _node.id === nodeId);
          if (!_foundedNode || _foundedNode === undefined) {
            isValid = false;
          } else {
            if (
              _foundedNode &&
              _foundedNode?.[_inputRegisterId] === undefined
            ) {
              isValid = false;
            } else {
              isValid = true;
            }
          }
        }
      });
      if (isValid) {
        _variables.forEach((element) => {
          if (element?.name === value) {
            setVariable({
              name: element.name,
              type: "variable",
            });
          }
        });
        _constants.forEach((element) => {
          if (element?.name === value) {
            setVariable({
              name: element.name,
              type: "constant",
            });
          }
        });
      }
    };

    useEffect(() => {
      const _property =
        Object.keys(
          flowStore.nodes?.find((property) => property.id === nodeId)?.params
        ).length > 0
          ? flowStore.nodes.find((item) => item.id === nodeId)
          : null;

      const _inputValue = getNestedValue(_property, _inputRegisterId);
      if (!_inputValue && _inputValue !== "") {
        setValue(_inputRegisterId, input?.value);
      }
      if (!_property && getValues(_inputRegisterId) === input?.value) {
        setValue(_inputRegisterId, input?.value);
      }
      if (_inputValue) {
        setValue(_inputRegisterId, _inputValue);
      }
      return () => {
        unregister(_inputRegisterId);
        if (setSubInputs) {
          setSubInputs((e) => {
            const _e = [...e];
            return _e.filter((o) => o.id !== _inputRegisterId);
          });
        }
      };
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      const _value = getValues(_inputRegisterId);
      if (_value) {
        placingVariables(_value);
        if (setSubInputs) {
          setSubInputs((e) => {
            const _e = [...e];
            const _subInputs = input?.options?.find(
              (o) => o.id === _value
            )?.subInputs;
            const _inputIndex = _e.findIndex((o) => o.id === _inputRegisterId);
            if (_inputIndex > -1) {
              _e[_inputIndex].subInputs = _subInputs;
            } else {
              _e.push({
                id: _inputRegisterId,
                subInputs: _subInputs,
              });
            }
            return _e;
          });
        }
      }
      // eslint-disable-next-line
    }, [getValues(_inputRegisterId)]);

    const openVariableModalHandler = (e) => {
      e.preventDefault();
      if (!disableVariables) {
        setVariableModal(true);
      }
    };

    const closeVariableModalHandler = (e) => {
      e.stopPropagation();
      setVariableModal(false);
    };

    return (
      <StyledInput>
        <div className={`inputHolder ${className}`}>
          <div className="labelHolder">
            {input?.key && type !== "valueFetch" && <span>{input?.key}</span>}
          </div>
          {variable.name && variable.type && (
            <div
              onContextMenu={openVariableModalHandler}
              className={variable?.type}
            >
              <span>{variable?.name}</span>
            </div>
          )}{" "}
          <>
            {type === "text" ? (
              <input
                autoComplete="off"
                onContextMenu={openVariableModalHandler}
                type="text"
                style={{
                  display: variable.name && variable.type ? "none" : "block",
                }}
                {...register(_inputRegisterId, {
                  onChange: (e) => {
                    placingVariables(e.target.value);
                    inputChangeHandler &&
                      inputChangeHandler(input?.id, e.target.value);
                    onChange && onChange(e);
                  },
                })}
              />
            ) : type === "select" ? (
              <select
                autoComplete="off"
                style={{
                  display: variable.name && variable.type ? "none" : "block",
                  background: typeof getValues(_inputRegisterId) === 'string' && getValues(_inputRegisterId)?.startsWith("clr")
                    ? getValues(_inputRegisterId)?.substring(3).toLowerCase()
                    : "inherite",
                }}
                onContextMenu={openVariableModalHandler}
                {...register(_inputRegisterId, {
                  onChange: (e) => {
                    placingVariables(e.target.value);
                    inputChangeHandler &&
                      inputChangeHandler(input?.id, e.target.value);
                    onChange && onChange(e);
                  },
                })}
              >
                {input?.options &&
                  input?.options.map((_option, index) => (
                    <option
                      key={index + _option?.id + _option?.name}
                      value={_option?.id}
                      style={{
                        background: typeof _option?.id === 'string' && _option?.id?.startsWith("clr")
                          ? _option?.id?.substring(3).toLowerCase()
                          : "inherite",
                      }}
                    >
                      {_option?.name}
                    </option>
                  ))}
              </select>
            ) : (
              type === "valueFetch" && (
                <div className="valueFetchHolder">
                  {input?.key && <span>{input?.key}</span>}
                  <ConditionSection
                    id={_inputRegisterId}
                    nodeId={nodeId}
                    defaultRow1={input?.defaultRow1 ?? "value"}
                    defaultRow2={input?.defaultRow2 ?? ""}
                    defaultParams={defaultParams ?? null}
                  />
                </div>
              )
            )}
          </>
          {variableModal && (
            <SetVariableModal
              inputId={input?.id}
              registerId={_inputRegisterId}
              closeVariableModalHandler={closeVariableModalHandler}
              setValue={setValue}
              setVariable={setVariable}
              nodeId={nodeId}
            />
          )}
        </div>
      </StyledInput>
    );
  }
);

export default Input;
