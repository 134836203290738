import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(102, 102, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(102, 102, 102, 0);
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const StyledCustomIndicatorModal = styled.div`
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 20%;
  top: 10%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  width: 1000px;
  height: 700px;
  border-radius: 20px;
  overflow: hidden;
  animation: ${fadeIn} 0.3s ease-out;
  border: 1px solid rgba(102, 102, 102, 0.2);
  backdrop-filter: blur(10px);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    background: linear-gradient(90deg, #666666 0%, #4a4a4a 100%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: move;
    position: relative;
    overflow: hidden;

    .title-section {
      display: flex;
      align-items: center;
      gap: 16px;
      
      span {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        letter-spacing: 0.5px;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 12px;

      .action-button {
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        
        svg {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          transform: translateY(-1px);
        }

        &.clear {
          background: rgba(255, 0, 0, 0.1);
          border-color: rgba(255, 0, 0, 0.2);

          &:hover {
            background: rgba(255, 0, 0, 0.2);
          }
        }

        &.new {
          background: rgba(102, 102, 102, 0.2);
          border-color: #666666;

          &:hover {
            background: rgba(102, 102, 102, 0.3);
          }
        }
      }

      .close-button {
        padding: 8px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 20px;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          transform: rotate(90deg);
        }

        &:active {
          transform: rotate(90deg) scale(0.95);
        }
      }
    }
  }

  .modal-layout {
    display: flex;
    height: calc(100% - 72px);
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  }

  .sidebar {
    width: 250px;
    background: linear-gradient(180deg, rgba(102, 102, 102, 0.1) 0%, rgba(102, 102, 102, 0.05) 100%);
    padding: 24px;
    border-right: 1px solid rgba(102, 102, 102, 0.2);
    backdrop-filter: blur(10px);
    
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 20px;
      padding-bottom: 12px;
      border-bottom: 2px solid #666666;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 50%;
        height: 2px;
        background: linear-gradient(90deg, #666666, transparent);
      }
    }

    .indicator-list {
      .indicator-item {
        padding: 14px 18px;
        margin-bottom: 12px;
        background: linear-gradient(145deg, rgba(102, 102, 102, 0.1), rgba(102, 102, 102, 0.05));
        border-radius: 12px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid rgba(102, 102, 102, 0.2);
        color: #fff;
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            45deg,
            transparent 0%,
            rgba(255, 255, 255, 0.05) 50%,
            transparent 100%
          );
          transform: translateX(-100%);
          transition: transform 0.6s ease;
        }

        &:hover {
          background: linear-gradient(145deg, rgba(102, 102, 102, 0.2), rgba(102, 102, 102, 0.15));
          transform: translateY(-2px);
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
          border-color: #666666;

          &:before {
            transform: translateX(100%);
          }
        }
      }
    }
  }

  .modal-content {
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0.8) 0%, rgba(45, 45, 45, 0.8) 100%);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(102, 102, 102, 0.1);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #666666 0%, #4a4a4a 100%);
      border-radius: 3px;

      &:hover {
        background: linear-gradient(180deg, #777777 0%, #5a5a5a 100%);
      }
    }

    .form-group {
      margin-bottom: 24px;
      background: linear-gradient(145deg, rgba(102, 102, 102, 0.1), rgba(102, 102, 102, 0.05));
      padding: 20px;
      border-radius: 16px;
      border: 1px solid rgba(102, 102, 102, 0.2);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      }

      label {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 12px;
        letter-spacing: 0.5px;
      }

      input[type="file"] {
        width: 100%;
        padding: 16px;
        background: rgba(102, 102, 102, 0.1);
        border: 2px dashed rgba(102, 102, 102, 0.3);
        border-radius: 12px;
        cursor: pointer;
        color: #fff;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(102, 102, 102, 0.15);
          border-color: #666666;
          animation: ${pulse} 2s infinite;
        }
      }

      input[type="text"] {
        width: 100%;
        padding: 12px 16px;
        background: rgba(102, 102, 102, 0.1);
        border: 1px solid rgba(102, 102, 102, 0.2);
        border-radius: 8px;
        color: #fff;
        transition: all 0.3s ease;
        font-size: 14px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        &:focus {
          background: rgba(102, 102, 102, 0.15);
          border-color: #666666;
          box-shadow: 0 0 0 3px rgba(102, 102, 102, 0.2);
          outline: none;
        }
      }
    }

    .enumerations,
    .input-parameters,
    .output-buffers {
      margin-bottom: 28px;
      background: linear-gradient(165deg, rgba(40, 40, 40, 0.95), rgba(25, 25, 25, 0.95));
      padding: 24px;
      border-radius: 16px;
      border: 1px solid rgba(102, 102, 102, 0.2);
      transition: all 0.3s ease;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(90deg, transparent, rgba(102, 102, 102, 0.5), transparent);
      }

      .section-title {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 2px solid rgba(102, 102, 102, 0.3);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 60px;
          height: 2px;
          background: linear-gradient(90deg, #666666, transparent);
        }

        &:before {
          content: '';
          position: absolute;
          bottom: -2px;
          right: 0;
          width: 60px;
          height: 2px;
          background: linear-gradient(90deg, transparent, #666666);
        }
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
        border-color: rgba(102, 102, 102, 0.4);

        .section-title:after {
          width: 80px;
          transition: width 0.3s ease;
        }

        .section-title:before {
          width: 80px;
          transition: width 0.3s ease;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;

        thead tr {
          background: linear-gradient(90deg, #666666 0%, #4a4a4a 100%);
          border-radius: 8px;
          
          th {
            padding: 12px 16px;
            color: #fff;
            font-weight: 500;
            text-align: left;
            letter-spacing: 0.5px;
            
            &:first-child {
              border-radius: 8px 0 0 8px;
            }
            
            &:last-child {
              border-radius: 0 8px 8px 0;
              width: 50px;
            }
          }
        }

        tbody tr {
          animation: ${slideIn} 0.3s ease-out;
          background: rgba(50, 50, 50, 0.3);
          border-radius: 8px;
          transition: all 0.2s ease;

          &:hover {
            background: rgba(60, 60, 60, 0.4);
            transform: translateX(4px);
          }

          td {
            padding: 8px;
            position: relative;

            &:first-child {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
              width: 50px;
              padding-right: 16px;
            }

            input {
              width: 100%;
              padding: 10px 14px;
              background: rgba(30, 30, 30, 0.5);
              border: 1px solid rgba(102, 102, 102, 0.2);
              border-radius: 6px;
              color: #fff;
              transition: all 0.2s ease;
              font-size: 14px;

              &:focus {
                background: rgba(40, 40, 40, 0.5);
                border-color: #666666;
                box-shadow: 0 0 0 3px rgba(102, 102, 102, 0.2);
                outline: none;
              }
            }
          }

          .remove-button {
            width: 28px;
            height: 28px;
            border-radius: 6px;
            background: rgba(255, 59, 59, 0.1);
            border: 1px solid rgba(255, 59, 59, 0.2);
            color: #ff3b3b;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2s ease;
            font-size: 18px;
            margin-left: auto;

            &:hover {
              background: rgba(255, 59, 59, 0.2);
              transform: scale(1.05);
            }

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }

      .add-button {
        margin-top: 20px;
        padding: 12px 20px;
        background: linear-gradient(145deg, #666666, #4a4a4a);
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            45deg,
            transparent 0%,
            rgba(255, 255, 255, 0.1) 50%,
            transparent 100%
          );
          transform: translateX(-100%);
          transition: transform 0.6s ease;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background: linear-gradient(145deg, #777777, #555555);
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(102, 102, 102, 0.3);

          &:before {
            transform: translateX(100%);
          }
        }
      }
    }

    .input-parameters,
    .output-buffers {
      label {
        display: none;
      }
    }

    button {
      padding: 14px 28px;
      background: linear-gradient(145deg, #666666, #4a4a4a);
      color: white;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;
      width: 100%;
      font-size: 16px;
      letter-spacing: 0.5px;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          45deg,
          transparent 0%,
          rgba(255, 255, 255, 0.1) 50%,
          transparent 100%
        );
        transform: translateX(-100%);
        transition: transform 0.6s ease;
      }

      &:hover {
        background: linear-gradient(145deg, #777777, #555555);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(102, 102, 102, 0.3);

        &:before {
          transform: translateX(100%);
        }
      }
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    animation: ${fadeIn} 0.2s ease-out;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 3px solid #666666;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  button.loading {
    position: relative;
    cursor: not-allowed;
    opacity: 0.8;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.1),
        transparent
      );
      animation: shimmer 1.5s infinite;
    }
  }
`;
