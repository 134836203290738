export const parseMQ4File = (fileContent) => {
  const indicatorNameMatch = fileContent.match(
    /#property\s+indicator_name\s+"(.*?)"/
  );
  const enumerationsMatch = fileContent.match(/enum\s+(\w+)\s*{(.*?)}/g);
  const inputParametersMatch = fileContent.match(
    /input\s+(.*?)\s+(\w+)\s*=\s*(.*?);/g
  );
  const outputBuffersMatch = fileContent.match(
    /SetIndexBuffer\s*\((\d),\s*(\w+)\);/g
  );

  const enumerations = enumerationsMatch
    ? enumerationsMatch.map((enumDef) => {
        return enumDef;
      })
    : [];
  const inputParameters = inputParametersMatch
    ? inputParametersMatch.map((paramDef) => {
        const [_, type, name, defaultValue] = paramDef.match(
          /input\s+(.*?)\s+(\w+)\s*=\s*(.*?);/
        );
        return { type, name, defaultValue };
      })
    : [];
  const outputBuffers = outputBuffersMatch
    ? outputBuffersMatch.map((bufferDef) => {
        const [_, index, name] = bufferDef.match(
          /SetIndexBuffer\s*\((\d),\s*(\w+)\);/
        );
        return { color: generateRandomColor(), name };
      })
    : [];

  return {
    indicatorName: indicatorNameMatch ? indicatorNameMatch[1] : "Unknown",
    enumerations,
    inputParameters,
    outputBuffers,
  };
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
