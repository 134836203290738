import React, { useState, useEffect } from "react";
import { StyledCustomIndicatorModal } from "./StyledCustomIndicatorModal";
import {
  parseMQ4File,
  generateRandomColor,
} from "../../utils/functions/mq4Parser"; // Helpers
import Draggable from "react-draggable";
import { useForm } from "react-hook-form";
import useFetch from "../../utils/hooks/useFetch";
import { CUSTOM_INDICATORS } from "../../constants/routes/api-routes";
import useMutate from "../../utils/hooks/useMutate";
import { toast } from "react-toastify";
const CustomIndicatorModal = ({ setModal }) => {
  const { register } = useForm();
  const {
    mutate: createCustomIndicator,
    isPending: isCreating,
    isSuccess,
  } = useMutate("POST", CUSTOM_INDICATORS, CUSTOM_INDICATORS);
  const {
    data: indicators,
    isFetching,
    refetch,
  } = useFetch("GET", CUSTOM_INDICATORS, CUSTOM_INDICATORS);
  const [indicatorData, setIndicatorData] = useState({
    indicatorName: "",
    enumerations: [],
    inputParameters: [],
    outputBuffers: [],
  });
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);
  const {
    mutate: updateCustomIndicator,
    isPending: isUpdating,
    isSuccess: isUpdateSuccess,
  } = useMutate(
    "PUT",
    CUSTOM_INDICATORS + selectedIndicatorId,
    CUSTOM_INDICATORS + selectedIndicatorId
  );

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const fileContent = evt.target.result;
        const fileName = file.name.replace(".mq4", "");
        const parsedData = parseMQ4File(fileContent);
        setIndicatorData({
          ...parsedData,
          indicatorName: fileName,
        });
      };
      reader.readAsText(file);
    }
  };

  const loadIndicator = (indicator) => {
    const parsedData = JSON.parse(indicator.json_file);
    const updatedData = {
      ...parsedData,
      outputBuffers: parsedData.outputBuffers.map((buffer) => ({
        ...buffer,
        color: buffer.color || generateRandomColor(),
      })),
    };
    setIndicatorData(updatedData);
    setSelectedIndicatorId(indicator.id);
  };

  const addNewInput = () => {
    setIndicatorData({
      ...indicatorData,
      inputParameters: [
        ...indicatorData.inputParameters,
        { type: "new_type", name: "new_param", defaultValue: "default" },
      ],
    });
  };

  const addNewBuffer = () => {
    setIndicatorData({
      ...indicatorData,
      outputBuffers: [
        ...indicatorData.outputBuffers,
        {
          name: "new_buffer",
          color: generateRandomColor().startsWith("#")
            ? generateRandomColor()
            : `#${generateRandomColor()}`,
        },
      ],
    });
  };

  const handleEnumChange = (index, value) => {
    const newEnums = [...indicatorData.enumerations];
    newEnums[index] = value;
    setIndicatorData({ ...indicatorData, enumerations: newEnums });
  };

  const handleParamChange = (index, field, value) => {
    const newParams = [...indicatorData.inputParameters];
    newParams[index] = { ...newParams[index], [field]: value };
    setIndicatorData({ ...indicatorData, inputParameters: newParams });
  };

  const handleBufferChange = (index, field, value) => {
    const newBuffers = [...indicatorData.outputBuffers];
    const colorValue = field === "color" ? value.replace("#", "") : value;
    newBuffers[index] = { ...newBuffers[index], [field]: colorValue };
    setIndicatorData({ ...indicatorData, outputBuffers: newBuffers });
  };

  const createCustomIndicatorHandler = () => {
    createCustomIndicator({
      child_name: indicatorData.indicatorName,
      json_file: JSON.stringify(indicatorData),
      children_name_indicator: 8,
    });
  };

  const updateCustomIndicatorHandler = () => {
    updateCustomIndicator({
      child_name: indicatorData.indicatorName,
      json_file: JSON.stringify(indicatorData),
      children_name_indicator: 8,
    });
  };

  const handleClearData = () => {
    setIndicatorData({
      indicatorName: "",
      enumerations: [],
      inputParameters: [],
      outputBuffers: [],
    });
    setSelectedIndicatorId(null);
  };

  const handleNewIndicator = () => {
    setIndicatorData({
      indicatorName: "New Indicator",
      enumerations: [],
      inputParameters: [],
      outputBuffers: [],
    });
    setSelectedIndicatorId(null);
  };

  const addNewEnum = () => {
    setIndicatorData({
      ...indicatorData,
      enumerations: [...indicatorData.enumerations, "new_enum"],
    });
  };

  const removeEnum = (index) => {
    const newEnums = [...indicatorData.enumerations];
    newEnums.splice(index, 1);
    setIndicatorData({ ...indicatorData, enumerations: newEnums });
  };

  const removeInput = (index) => {
    const newInputs = [...indicatorData.inputParameters];
    newInputs.splice(index, 1);
    setIndicatorData({ ...indicatorData, inputParameters: newInputs });
  };

  const removeBuffer = (index) => {
    const newBuffers = [...indicatorData.outputBuffers];
    newBuffers.splice(index, 1);
    setIndicatorData({ ...indicatorData, outputBuffers: newBuffers });
  };

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      refetch();
      toast.success(
        isSuccess
          ? "Indicator created successfully!"
          : "Indicator updated successfully!"
      );
    }
  }, [isSuccess, isUpdateSuccess]);

  return (
    <Draggable handle=".header">
      <StyledCustomIndicatorModal>
        <div className="header">
          <div className="title-section">
            <span>Custom Indicator</span>
          </div>
          <div className="controls">
            <button className="action-button new" onClick={handleNewIndicator}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M12 5v14M5 12h14" />
              </svg>
              New
            </button>
            <button className="action-button clear" onClick={handleClearData}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3-3h6M9 10v8M15 10v8" />
              </svg>
              Clear
            </button>
            <button className="close-button" onClick={() => setModal(false)}>
              ×
            </button>
          </div>
        </div>
        <div className="modal-layout">
          <div className="sidebar">
            <h3>Saved Indicators</h3>
            <div className="indicator-list">
              {indicators?.data?.map((indicator) => (
                <div
                  key={indicator.id}
                  className="indicator-item"
                  onClick={() => loadIndicator(indicator)}
                >
                  {indicator.child_name}
                </div>
              ))}
            </div>
          </div>
          <div className="modal-content">
            {isFetching && (
              <div className="loading-overlay">
                <div className="loader"></div>
              </div>
            )}
            <div className="form-group">
              <label>Load From File</label>
              <input type="file" onChange={handleFileUpload} />
            </div>
            <div className="form-group">
              <label>Indicator Name</label>
              <input
                type="text"
                {...register("indicatorName")}
                value={indicatorData.indicatorName}
                onChange={(e) =>
                  setIndicatorData({
                    ...indicatorData,
                    indicatorName: e.target.value,
                  })
                }
              />
            </div>

            <div className="enumerations">
              <div className="section-title">Enumerations</div>
              <table>
                <thead>
                  <tr>
                    <th>Enumeration Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {indicatorData.enumerations.map((enumValue, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={enumValue}
                          onChange={(e) =>
                            handleEnumChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="remove-button"
                          onClick={() => removeEnum(index)}
                        >
                          ×
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="add-button" onClick={addNewEnum}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
                Add Enumeration
              </button>
            </div>

            <div className="input-parameters">
              <div className="section-title">Input Parameters</div>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Default Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {indicatorData.inputParameters.map((param, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={param.type}
                          onChange={(e) =>
                            handleParamChange(index, "type", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={param.name}
                          onChange={(e) =>
                            handleParamChange(index, "name", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={param.defaultValue}
                          onChange={(e) =>
                            handleParamChange(
                              index,
                              "defaultValue",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="remove-button"
                          onClick={() => removeInput(index)}
                        >
                          ×
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="add-button" onClick={addNewInput}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
                Add Input Parameter
              </button>
            </div>

            <div className="output-buffers">
              <div className="section-title">Output Buffers</div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {indicatorData.outputBuffers.map((buffer, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={buffer.name}
                          onChange={(e) =>
                            handleBufferChange(index, "name", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="color"
                          value={buffer.color}
                          onChange={(e) =>
                            handleBufferChange(index, "color", e.target.value)
                          }
                          style={{
                            width: "40px",
                            height: "24px",
                            padding: "0",
                            border: "none",
                            background: "none",
                          }}
                        />
                      </td>
                      <td>
                        <button
                          className="remove-button"
                          onClick={() => removeBuffer(index)}
                        >
                          ×
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="add-button" onClick={addNewBuffer}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
                Add Output Buffer
              </button>
            </div>

            <div className="actions">
              <button
                onClick={
                  selectedIndicatorId
                    ? updateCustomIndicatorHandler
                    : createCustomIndicatorHandler
                }
                disabled={isCreating || isUpdating}
                className={isCreating || isUpdating ? "loading" : ""}
              >
                {isCreating || isUpdating
                  ? selectedIndicatorId
                    ? "Updating..."
                    : "Creating..."
                  : selectedIndicatorId
                  ? "Update"
                  : "Create"}
              </button>
            </div>
          </div>
        </div>
      </StyledCustomIndicatorModal>
    </Draggable>
  );
};

export default CustomIndicatorModal;
